import React, { useCallback, useState } from 'react'
import {
  AccordionProps,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
} from '@mui/material'
import { withStyles } from 'tss-react/mui'
import { ExpandMore } from '@mui/icons-material'
import { FAQ as ContentfulFAQ } from '../../types/faq'
import RichText from '../RichText'
import useEvents from '../../hooks/useEvents'

interface Props extends ContentfulFAQ {
  forceExpand?: boolean
  variant?: 'faq-page'
}

const FAQ: React.FC<Props> = ({
  question,
  answer,
  contentful_id: faqID,
  forceExpand = false,
}) => {
  const [expanded, setExpanded] = useState(forceExpand)
  const sendEvent = useEvents()

  const handleExpansion =
    // I want to type this correctly without typing it out, so I'm using an
    // overly generic Extract to get the function I want without the undefined.
    // Our eslint rules do not like this.
    /* eslint-disable-next-line @typescript-eslint/ban-types */
    useCallback<Extract<AccordionProps['onChange'], Function>>(
      (_, exp) => {
        setExpanded(exp)
        sendEvent({
          event: `FAQ Question ${exp ? 'Expanded' : 'Collapsed'}`,
          variables: {
            contentful: {
              faqID,
            },
          },
        })
      },
      [sendEvent, faqID]
    )

  if (typeof question === 'string') {
    return (
      <Accordion
        onChange={handleExpansion}
        data-contentful-id={faqID}
        itemScope
        itemType="https://schema.org/Question"
        expanded={expanded}
      >
        <AccordionSummary
          component="h3"
          sx={{
            fontWeight: 'inherit', // Inherit the default font weight from parent styles
            fontSize: 'inherit', // Inherit the default font size from parent styles
            margin: 0, // Remove any default margin for headings
          }}
          itemProp="name"
          expandIcon={<ExpandMore />}
        >
          {question}
        </AccordionSummary>
        <AccordionDetails
          itemScope
          itemType="https://schema.org/Answer"
          itemProp="acceptedAnswer"
        >
          <div itemProp="text">{answer as any}</div>
        </AccordionDetails>
      </Accordion>
    )
  }

  return (
    <Accordion
      onChange={handleExpansion}
      data-contentful-id={faqID}
      itemScope
      itemType="https://schema.org/Question"
      expanded={expanded}
    >
      <AccordionSummary itemProp="name" expandIcon={<ExpandMore />}>
        <RichText rawBody={question.raw} references={question.references} />
      </AccordionSummary>
      <AccordionDetails
        itemScope
        itemType="https://schema.org/Answer"
        itemProp="acceptedAnswer"
      >
        <div itemProp="text">
          <RichText rawBody={answer.raw} references={answer.references} />
        </div>
      </AccordionDetails>
    </Accordion>
  )
}

const Accordion = withStyles(MuiAccordion, (theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: 'transparent',

    '&:last-child': {
      borderBottom: 0,
    },

    '&::before': {
      backgroundColor: 'transparent',
    },

    '&:last-child > div:first-child': {
      paddingBottom: 0,
    },

    '&:last-child .MuiAccordionDetails-root': {
      paddingBottom: 0,
    },
  },
  expanded: {
    '&&': {
      margin: 0,
    },
  },
}))

const AccordionSummary = withStyles(MuiAccordionSummary, (theme) => ({
  root: {
    paddingTop: '1.53125rem',
    paddingBottom: '1.46875rem',
    paddingLeft: 0,

    '&.Mui-expanded': {
      paddingBottom: 0,

      '& p': {
        fontWeight: 700,
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: '.75rem',
      },
    },

    '& p': {
      fontSize: '1.375rem',
      lineHeight: 1.5,

      [theme.breakpoints.down('md')]: {
        fontSize: '1.13rem',
      },
    },

    '&:hover': {
      color: theme.palette.primary.main,
      [theme.breakpoints.down('md')]: {
        color: 'unset',
      },
    },

    '&:focus': {
      outline: 0,
    },
  },
  content: {
    marginTop: 0,
    marginBottom: 0,
  },
  // The following dimensions are in px because that is what Material UI is
  // using for the unexpanded versions that I want to match when expanded. To
  // convert to em/rem here could cause issues when in different containers.
  expanded: {
    '&&': {
      minHeight: 0,
      margin: 0,
    },
  },
  expandIconWrapper: {
    '&&': {
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: '-12px',
    },
  },
}))

const AccordionDetails = withStyles(MuiAccordionDetails, () => ({
  root: {
    paddingLeft: 0,

    '& p + p': {
      marginTop: '1em',
    },
  },
}))

export default FAQ
