import React from 'react'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import loadable from '@loadable/component'
// import { useScrollToViewPackages } from '../../hooks/useScrollToViewPackages'
import { LandingPageV2 } from '../../types/landingPageV2'
import { ProductPage } from '../../types/productPageV2'
import { useCheckoutEvent } from '../../hooks/useCheckoutEvent'
import FAQGroup from '../FAQ/FAQGroup'
import HeroTextSection from '../HeroTextSection'
import CallToAction from '../CallToAction'
import Banner from '../Banner/Banner'
import RelatedPosts from '../RelatedPosts'
import CustomComponent from '../CustomComponent'
import PackageSection from '../Packages/PackageSection'
import PackagesSection from '../Packages/PackagesSection'
import ReviewSection from '../Reviews/ReviewSection'
import ReviewSectionNonTrustPilot from '../Reviews/ReviewSectionNonTrustPilot'
import CarouselSection from '../LandingPage/CarouselSection'
import Spacer from '../Spacer'
import HeroTextTwoColumnSection from '../HeroTextTwoColumnSection'
import HorizontalSplit2ColumnSection from '../HorizontalSplit2ColumnSection/HorizontalSplit2ColumnSection'
import XRayWrapper from '../XRay/XRayWrapper'
import RoundBackgroundBlock from '../RoundBackgroundBlock/RoundBackgroundBlock'
import AuthorSocialMediaSection from '../Author/AuthorSocialMediaSection'
import AuthorPosts from '../Author/AuthorPosts'
import ProductPackagesSection from '../ProductPackages/ProductPackagesSection'
import ProductPackageSection from '../ProductPackages/ProductPackageSection'
import NewProductPackagesSection from '../NewProductPackages/NewProductPackagesSection'
import NewProductPackageSection from '../NewProductPackages/NewProductPackageSection'
import NewProductPackagesSectionV2 from '../NewProductPackagesV2/NewProductPackagesSectionV2'
import NewProductPackageSectionV2 from '../NewProductPackagesV2/NewProductPackageSectionV2'
import NewProductPackagesSectionV3 from '../NewProductPackagesV3/NewProductPackagesSectionV3'
import NewProductPackageSectionV3 from '../NewProductPackagesV3/NewProductPackageSectionV3'
import NewProductPackagesSectionV4 from '../NewProductPackagesV4/NewProductPackagesSectionV4'
import NewProductPackageSectionV4 from '../NewProductPackagesV4/NewProductPackageSectionV4'
import NewProductPackagesSectionV5 from '../NewProductPackagesV5/NewProductPackagesSectionV5'
import NewProductPackageSectionV5 from '../NewProductPackagesV5/NewProductPackageSectionV5'
import NewProductPackagesSectionV6 from '../TotalJobSeachLowLeadBands/NewProductPackagesSectionV6'
import NewProductPackageSectionV6 from '../TotalJobSeachLowLeadBands/NewProductPackageSectionV6'
import { DynamicProductsSection } from '../DynamicProductsSection'
import '../../../common-styles/common-components.css'
import '../../../common-styles/privacy-policy.css'
import '../../../common-styles/cookie-consent.css'
import { AffirmProviderProvider } from '@talentinc/gatsby-theme-ecom/components/AffirmNewTest/AffirmProvider'

interface Props {
  landingPage: LandingPageV2 | ProductPage
}

const LandingPageV2Template: React.FC<Props> = ({ landingPage }) => {
  const { classes } = useStyles()

  useCheckoutEvent(landingPage)

  const baseJSX = (
    <Box className={classes.container} component="main">
      <AffirmProviderProvider>
        <Box className={classes.containerInner}>
          {landingPage.rows.map((row) => (
            <XRayWrapper id={row.contentful_id} key={row.contentful_id}>
              <LandingPageSection row={row} />
            </XRayWrapper>
          ))}
        </Box>
      </AffirmProviderProvider>
    </Box>
  )

  if (landingPage.slug.includes('match-report')) {
    // Call the match API by providing the provider only if the user's on match landing page
    const MatchDataProvider = loadable(
      () => import('../../components/Providers/MatchData')
    )
    return <MatchDataProvider>{baseJSX}</MatchDataProvider>
  }

  return baseJSX
}

const LandingPageSection: React.FC<{
  row: LandingPageV2['rows'][0]
}> = ({ row }) => {
  const { classes } = useStyles()
  // const { isDelayed } = useScrollToViewPackages(row.__typename)
  // if (
  //   !isDelayed &&
  //   row.__typename !== 'ContentfulProductPackagesSectionNewVersion4'
  // ) {
  //   return null
  // }

  switch (row.__typename) {
    case 'ContentfulFrequentlyAskedQuestionGroup':
      return (
        <FAQGroup
          faqSection={true}
          group={row}
          sectionClass={classes.sectionPadding}
        />
      )
    case 'ContentfulHeroTextSection':
      return <HeroTextSection sectionClass={classes.sectionPadding} row={row} />
    case 'ContentfulHeroTextTwoColumnSection':
      return (
        <HeroTextTwoColumnSection
          row={row}
          sectionClass={classes.sectionPadding}
        />
      )
    case 'ContentfulCallToAction':
      return <CallToAction cta={row} sectionClass={classes.sectionPadding} />
    case 'ContentfulNavigationGroup':
      return (
        <RelatedPosts
          displayClass={classes.container}
          links={row.links}
          sectionClass={classes.sectionPadding}
          title={row.title}
        />
      )
    case 'ContentfulBanner':
      return <Banner banner={row} sectionClass={classes.sectionPadding} />
    case 'ContentfulCustomComponent':
      return (
        <CustomComponent item={row} sectionClass={classes.sectionPadding} />
      )
    case 'ContentfulPackagesSection':
      return (
        <PackagesSection
          packagesSection={row}
          sectionClass={classes.sectionPadding}
        />
      )
    case 'ContentfulPackageSection':
      return <PackageSection packageSection={row} />
    case 'ContentfulReviewSection':
      return (
        <ReviewSection
          reviewSection={row}
          sectionClass={classes.sectionPadding}
        />
      )
    case 'ContentfulReviewSectionNonTrustPilot':
      return <ReviewSectionNonTrustPilot section={row} />
    case 'ContentfulCarousel':
      return <CarouselSection carouselSection={row} />
    case 'ContentfulSpacer':
      return <Spacer item={row} />
    case 'ContentfulHorizontalSplit2ColumnSection':
      return (
        <HorizontalSplit2ColumnSection
          section={row}
          sectionClass={classes.sectionPadding}
        />
      )
    case 'ContentfulProductPackagesSection': {
      return (
        <ProductPackagesSection
          packagesSection={row}
          sectionClass={classes.sectionPadding}
        />
      )
    }
    case 'ContentfulProductPackageSection': {
      return (
        <ProductPackageSection
          packageSection={row}
          sectionClass={classes.sectionPadding}
        />
      )
    }
    case 'ContentfulAuthorSocialMedia': {
      return <AuthorSocialMediaSection data={row} />
    }
    case 'ContentfulProductPackagesSectionNew': {
      return (
        <NewProductPackagesSection
          packagesSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackageSectionNew': {
      return (
        <NewProductPackageSection
          packageSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
          expanded={false}
          onExpandClick={() => {
            // Placeholder ExpandClick
          }}
        />
      )
    }
    case 'ContentfulAuthorPosts': {
      return <AuthorPosts data={row} />
    }
    case 'ContentfulProductPackagesSectionNewVersion2': {
      return (
        <NewProductPackagesSectionV2
          packagesSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackageSectionNewVersion2': {
      return (
        <NewProductPackageSectionV2
          packageSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackagesSectionNewVersion3': {
      return (
        <NewProductPackagesSectionV3
          packagesSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackageSectionNewVersion3': {
      return (
        <NewProductPackageSectionV3
          packageSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackagesSectionNewVersion4': {
      return (
        <NewProductPackagesSectionV4
          packagesSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackageSectionNewVersion4': {
      return (
        <NewProductPackageSectionV4
          packageSection={row}
          sectionClass={classes.sectionPaddingYesPadding}
        />
      )
    }
    case 'ContentfulProductPackagesSectionNewVersion5': {
      return (
        <NewProductPackagesSectionV5
          packagesSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackageSectionNewVersion5': {
      return (
        <NewProductPackageSectionV5
          packageSection={row}
          sectionClass={classes.sectionPaddingYesPadding}
        />
      )
    }

    case 'ContentfulProductPackagesSectionNewVersion6': {
      return (
        <NewProductPackagesSectionV6
          packagesSection={row}
          sectionClass={classes.sectionPaddingNoPadding}
        />
      )
    }
    case 'ContentfulProductPackageSectionNewVersion6': {
      return (
        <NewProductPackageSectionV6
          packageSection={row}
          sectionClass={classes.sectionPaddingYesPadding}
        />
      )
    }
    case 'ContentfulRoundBackgroundBlock': {
      return (
        <RoundBackgroundBlock
          codeClasses={row.codeClasses}
          variant={row.variant}
          content={row.content}
          images={row.images}
          sectionClass={classes.sectionPadding}
          bottom={row.bottom}
        />
      )
    }
    case 'ContentfulDynamicProductsSection': {
      return <DynamicProductsSection pageKind={row.pageKind} />
    }
    default:
      return null
  }
}

const useStyles = makeStyles()((theme) => ({
  container: {
    maxWidth: '100%',
    width: '100%',
  },
  containerInner: {
    maxWidth: '100%',
    width: '100%',
  },
  sectionPadding: {
    paddingLeft: '6%',
    paddingRight: '6%',

    '@media (min-width: 1456px)': {
      paddingLeft: 'calc((100% - 91rem)/2 + 5.5rem)',
      paddingRight: 'calc((100% - 91rem)/2 + 5.5rem)',
    },
  },
  sectionPaddingNoPadding: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '5rem',
      paddingRight: '5rem',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '3.5rem',
      paddingRight: '3.5rem',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '2.5rem',
      paddingBottom: '2.5rem',
      width: '100%',
      margin: '0 auto',
      overflow: 'hidden',
    },
  },
  sectionPaddingYesPadding: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '5rem',
      paddingRight: '5rem',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '3.5rem',
      paddingRight: '3.5rem',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: '2rem',
      paddingRight: '2rem',
      paddingTop: '3rem',
      paddingBottom: '3rem',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '2.5rem',
      paddingBottom: '2.5rem',
      width: '100%',
      margin: '0 auto',
      overflow: 'hidden',
    },
  },
}))

export default LandingPageV2Template
